<template>
  <div>
     <headers />
     <div class="mb" style="padding:70px 15px 0 15px;">
      
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">{{title}}
          <router-link :to="{path:'/'}" class="btn btn-primary waves-effect waves-light btn-back-fixed">
      <i class="mdi mdi-arrow-left-bold"></i> {{$language('一覧')}}
    </router-link>
    </h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card-box">
          <list-table :viewParams="viewParams" />
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
import Headers from '@/components/Headers.vue'
import ListTable from '@/components/ListTableGroup.vue'
import router from '@/router'

export default {
  name: 'CorpList',
  components: {
    ListTable,Headers
  },
  data() {
    return {
      title:'',
      viewParams: {
        name: 'corp1',
        apiUrl: '/v1/corps_group/',
        articleView: 'CorpGroup',
        articleParamId: 'ID',
        dataType:100,
        tolink:'',
        gType:'',
        info_id:'corp_name',
        jump_url:''
      }
    }
  }, mounted() {
    const { currentRoute } = router

    let type = currentRoute.value.query.type || localStorage.getItem('corplistgroup_type')
      let info_id = 'corp_name';  
      if(type == 'building' || type == 'rent'){
        info_id = 'BuildingName'
      }

    if(currentRoute.value.query.title==undefined || currentRoute.value.query.title==''){
      this.title=localStorage.getItem('corplistgroup_title');
      
      
      this.viewParams={
        name: 'corp1',
        apiUrl: '/v1/corps_group/',
        articleView: 'CorpGroup',
        articleParamId: 'ID',
        dataType:100,
        tolink:localStorage.getItem('corplistgroup_tolink'),
        gType:type,
        info_id: info_id,
        jump_url:''
      }; 
      
    }else{
      this.title=currentRoute.value.query.title==undefined ? '': currentRoute.value.query.title;
      localStorage.setItem('corplistgroup_title',this.title);
      localStorage.setItem('corplistgroup_tolink','corp_list_group');
      localStorage.setItem('corplistgroup_type',currentRoute.value.query.type)
      
     this.viewParams={
        name: 'corp1',
        apiUrl: '/v1/corps_group/',
        articleView: 'CorpGroup',
        articleParamId: 'ID',
        dataType:100,
        tolink:localStorage.getItem('corplistgroup_tolink'),
        gType:type,
        info_id: info_id,
        jump_url:''
      };
      
    }
  }
}
</script>